<template>
  <section class="standup-admin">
    <h1>Set Vacation / Sick leave status</h1>
    <p>
      Employees who are checked will not be included in the standup sessions.
    </p>
    <p class="fetching" v-if="fetching">Fetching employees...</p>
    <ul v-else>
      <li v-for="employee in employees" v-bind:key="employee.email">
        <lunch-vacation-box
          :onVacation="onVacation(employee.email)"
          @setOnVacation="handleSetOnVacation($event, employee.email)"
        ></lunch-vacation-box>
        <p>{{ employee.firstName }} {{ employee.lastName }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
import { fetchPeople, getAllVacations, setUserVacationStatus } from "@/api";
import LunchVacationBox from "@/components/Tasks/LunchVacationBox";

export default {
  name: "StandupAdmin",
  components: {
    LunchVacationBox
  },
  data() {
    return {
      employees: [],
      vacations: [],
      fetching: false
    };
  },
  computed: {
    selectedOffice() {
      return this.$route.params.office;
    }
  },
  async created() {
    this.fetching = true;
    this.vacations = await getAllVacations();
    this.employees = await fetchPeople(this.selectedOffice);
    this.fetching = false;
  },
  methods: {
    onVacation(email) {
      const entity = this.vacations.find(e => e.email === email);
      if (!entity) return false;
      return entity.onVacation;
    },
    async handleSetOnVacation(e, email) {
      await setUserVacationStatus({ email, onVacation: e.target.checked });
    }
  }
};
</script>

<style scoped>
.standup-admin {
  padding: 2rem 1rem;
  background-color: #e8f8ef;
  height: 100vh;
  width: 100vw;
}
ul {
  margin-top: 1rem;
}
li {
  display: flex;
  margin-bottom: 0.5rem;
}

.fetching {
  margin-top: 2rem;
}
</style>
